
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import BasePageContainer from '@/components/reports-v2/pages/BasePageContainer.vue';
import moment from 'moment';
import PageIndicators from '@/components/reports-v2/components/pages/PageIndicators.vue';
import TitlePageIndex from '@/components/reports-v2/components/pages/TitlePageIndex.vue';
import AgentsListFilter from '@/components/reports-v2/components/filters/AgentsListFilter.vue';
import StockListFilter from '@/components/reports-v2/components/filters/StockListFilter.vue';
// import CustomerListFilter from '@/components/reports-v2/components/filters/CustomerListFilter.vue';
// import SupplierListFilter from '@/components/reports-v2/components/filters/SupplierListFilter.vue';
import DateRangeFilter from '@/components/reports-v2/components/filters/DateRangeFilter.vue';
import DateFilter from '@/components/reports-v2/components/filters/DateFilter.vue';
import AutoResizeSticky from '@/components/AutoResizeSticky.vue';
import StickyFilterContainer from '@/components/reports-v2/components/pages/StickyFilterContainer.vue';
import LastSync from '@/components/LastSync.vue';
import accountx from '@/store/modules/accountx';
import LocationListFilter from '@/components/reports-v2/components/filters/LocationListFilter.vue';

@Component({
	components: {
		AutoResizeSticky,
		PageIndicators,
		TitlePageIndex,
		AgentsListFilter,
		StockListFilter,
		// CustomerListFilter,
		// SupplierListFilter,
		DateFilter,
		DateRangeFilter,
		LastSync,
		StickyFilterContainer,
		LocationListFilter,
	},
})
export default class Stock extends BasePageContainer {
	public selectedAgents: string[] = [];
	// public selectedCustomers: string[] = [];
	// public selectedSuppliers: string[] = [];
	public selectedStockItems: string[] = [];
	public selectedLocations: string[] = [];
	public selectedAsOfDate: number = moment().startOf('day').valueOf();
	public selectedDateRange: [number, number] = [
		moment().startOf('month').valueOf(),
		moment().endOf('day').valueOf(),
	];
	public onDateAsOf(value: number) {
		this.selectedAsOfDate = value;
	}

	public get pages() {
		return [
			['Stock Availability', 'Stock Aging'],

			[
				'Stock Item Movement',
				'Stock Item Sales by Customer',
				'Stock Item Purchase by Supplier',
				'Stock Group Sales/Purchase',
			],
		];
	}
	public mounted() {
		(this.$refs.stickyBottom as any).handleScroll();
	}
}
